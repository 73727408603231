import React from "react";
import { GiThreeLeaves } from "react-icons/gi";
import { TbArrowsSplit } from "react-icons/tb";
import { GoPerson } from "react-icons/go";
import { FaRegLightbulb } from "react-icons/fa";


export const About = (props) => {
  return (
    <div id="about">
      <div className="container">
        <div className="row">
          <div className="col-xs-12 col-md-6">
            {" "}
            <img src="img/about.jpg" className="img-responsive" alt="Machine washable silk with silk dress better silk fabric among silk products" />{" "}
          </div>
          <div className="col-xs-12 col-md-6">
            <div className="about-text">
              <h2>About Us</h2>
              <p>{props.data ? props.data.paragraph : "loading..."}</p>
              <h3>Why Choose Us?</h3>
                <li>
                  <GiThreeLeaves className="icon"/>
                  <div className="point">{props.data ? props.data.Why[0] : "loading..."}</div>
                </li>
                <li>
                  <TbArrowsSplit className="icon"/>
                  <div className="point">{props.data ? props.data.Why[1] : "loading..."}</div>
                </li>
                <li>
                  <GoPerson className="icon"/>
                  <div className="point">{props.data ? props.data.Why[2] : "loading..."}</div>
                </li>
                <li>
                  <FaRegLightbulb className="icon"/>
                  <div className="point">{props.data ? props.data.Why[3] : "loading..."}</div>
                </li>
              {/* <div className="list-style"> */}
                {/* <div className="col-lg-6 col-sm-6 col-xs-12"> */}
                  {/* <ul>
                    {props.data
                      ? props.data.Why.map((d, i) => (
                          <li key={`${d}-${i}`}>{d}</li>
                        ))
                      : "loading"}
                  </ul> */}
                {/* </div> */}
                {/* <div className="col-lg-6 col-sm-6 col-xs-12">
                  <ul>
                    {props.data
                      ? props.data.Why2.map((d, i) => (
                          <li key={`${d}-${i}`}> {d}</li>
                        ))
                      : "loading"}
                  </ul>
                </div> */}
              {/* </div> */}
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};
