import React from "react";

export const Header = (props) => {
  return (
    <header id="header">
      <div className="intro">
        <div className="overlay">
          <div className="container">
            <div className="row">
              <div className="col-md-32 intro-text">
                <h1>
                  <img src="img/intro-bg.jpg" alt="Pink silk sheets, silk pajamas, and silk bedding in washer because of machine washable silk" style={{ display: 'none' }} /> {/* Hidden image */}
                  {props.data ? props.data.title : "Loading"}
                  <span></span>
                </h1>
                <p>{props.data ? props.data.tagline : "Loading"}</p>
              </div>
            </div>
          </div>
        </div>
      </div>
    </header>
  );
};
